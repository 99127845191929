@import url(https://www.bl-immobilien.net/templates/immo/build/style.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bl-valuation {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  padding: 40px 40px 20px;
  max-width: 900px;
  margin: 50px auto;
}

.bl-valuation h2 {
  text-align: center;
}

.bl-valuation .panel-content {
  margin-top: 70px;
}

.bl-valuation input {
  display: block;
  width: 100%;

  line-height: 40px;
  font-size: 20px;
  padding: 5px 15px;
  margin-bottom: 20px;

  background: #fff;
  outline: none;
  border: 2px solid #ccc;
  border-radius: 3px;

  min-width: 200px;
}

.bl-valuation .btn {
  padding: 5px 15px;
  line-height: 40px;
}

.bl-valuation input:focus {
  border-color: #003466;
}

.bl-valuation button {
  border: none;
}

.bl-valuation .steps {
  display: flex;
  align-items: center;
  max-width: 70%;
  margin: 20px auto;
}

.bl-valuation .step {
  height: 5px;
  flex-grow: 1;
  margin: 0 10px;
  background: #ccc;
  cursor: pointer;
}

.bl-valuation .step:hover {
  background: #aaa;
}

.bl-valuation .step.disabled {
  background: #ccc;
  cursor: default;
}

.bl-valuation .step.current {
  background: #003466;
}

.bl-valuation .card-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;

  min-width: 150px;
  height: 150px;

  cursor: pointer;

  outline: none;
}

.bl-valuation .card-btn.selected {
  border-color: #003466;
}

.bl-valuation .card-btn.text-only {
  height: auto;
  font-size: 0.9em;
}

.bl-valuation .card-btn:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.bl-valuation .card-btn img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.bl-valuation .card-btn .title {
  margin-top: 5px;
  color: #003466;
}

.bl-valuation .card-btn .icon {
  font-size: 40px;
  color: #777;
}

.bl-val-footer {
  margin-top: 100px;
}

.pac-item {
  padding: 5px 15px;
}

.pac-item-query {
  font-size: 20px;
}

.bl-valuation .input-group {
  margin-bottom: 20px;
}

.bl-valuation .form-control {
  display: flex;
}

.bl-valuation .form-control input {
  margin: 0;
}

.bl-valuation .form-control.has-addon input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bl-valuation .addon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ccc;
  line-height: 40px;
  font-size: 20px;
  padding: 5px 15px;
  border-left: none;

  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.bl-val-row {
  display: flex;
  width: 100%;
  align-items: center;
}

.bl-val-row > * {
  flex-grow: 1;
  flex-basis: 0;
  margin: 20px;
}

@media screen and (max-width: 750px) {
  .bl-valuation {
    padding: 10px;
    margin: 30px auto;
  }

  .bl-valuation .panel-content {
    margin-top: 30px;
  }

  .bl-val-row {
    flex-wrap: wrap;
  }

  .bl-val-footer {
    margin-top: 50px;
  }

  .bl-valuation .steps {
    max-width: 100%;
  }

  .bl-valuation .step {
    height: 10px;
  }

  .pac-item-query {
    font-size: 15px;
  }

  .bl-valuation input {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .bl-val-row > * {
    margin: 10px;
  }

  .bl-valuation .card-btn.text-only {
    font-size: 16px;
  }

  .bl-valuation .btn {
    display: block;
    width: 100%;
  }

  .bl-valuation .input-group {
    margin-bottom: 10px;
  }
}

